
import { defineComponent, ref } from "vue";
// import KTActivityItem1 from "./activity-timeline/Item1.vue";
import moment from "moment";

import { Form, Field, ErrorMessage } from "vee-validate";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Moment from "moment";

export default defineComponent({
  name: "activity",
  components: {
    // KTActivityItem1,
    // Form,
    // Field,
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const store = useStore();
    const user = {} as any;
    const appointmentsHistory = [];
    const isAppointmentsHistoryReady = false;
    const activeName = ref("1");

    const appointments = [];

    return {
      sessionDateTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      store: useStore(),
      route,
      router,
      cstmr_id,
      isNewAppointment: false,
      appointments,
      appointmentsReady: false,
      user,
      org_id: null,
      appointmentsHistory,
      isAppointmentsHistoryReady,
      activeName,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        this.user = this.store.getters.getProfile;
        this.org_id = this.user.organisation_id;
      });

      this.store.dispatch(Actions.GET_APPOINTMENTS, this.cstmr_id).then(() => {
        this.appointments = this.store.getters.getAppointnments;
        this.appointmentsReady = true;
        console.log(this.appointments);
      });

      this.store
        .dispatch(Actions.GET_APPOINTMENTS_HISTORY, this.cstmr_id)
        .then(() => {
          this.appointmentsHistory = this.store.getters.getAppointmentsHistoryData;
          this.isAppointmentsHistoryReady = true;
        });

      // let customer = this.store.getters.getCustomerData;
      // if (customer.appointments.length > 0) {
      //   for (const key in customer.appointments) {
      //     if (customer.appointments[key].status == "New") {
      //       this.isNewAppointment = true;
      //       break;
      //     }
      //   }
      // }
    },
    onClickViewAppoitnment(id) {
      window.location.href =
        "#/customers/details/" + this.cstmr_id + "/appointment/" + id;
    },
    today() {
      return moment().format("DD MMMM YYYY, dddd");
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getHumanDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    onCall(callType) {
      Swal.fire({
        text: "Creating " + callType + " Call",
        icon: "info",
        buttonsStyling: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
    },
    onStartNewSession() {
      Swal.fire({
        text: "Creating New Session",
        icon: "info",
        buttonsStyling: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      //Set Appointment
      const appointment = {
        id: "",
        slot_id: "",
        fa_id: this.user.id,
        user_id: this.cstmr_id,
        method_id: 3,
        service_id: 27,
        organisation_id: this.org_id,
        appointment_date: Moment().format("YYYY-MM-DD"),
        slot_type: "appointment",
        start_time: Moment().format("HH:mm:ss"),
        end_time: Moment().add(30, "minutes").format("HH:mm:ss"),
        status: "New",
        fa_reference: "New Adhoc Session",
        source_type_id: 3,
      };

      this.store
        .dispatch(Actions.PRE_STORE_APPOINTMENT, appointment)
        .then(() => {
          let data = this.store.getters.getAppointmentData.data;
          appointment.id = data.id;
          appointment.slot_id = data.id;
          this.store
            .dispatch(Actions.STORE_RAW, {
              type: "appointment",
              user_id: this.cstmr_id,
              raw: appointment,
            })
            .then(() => {
              //Start the session
              let slot = JSON.parse(JSON.stringify(appointment));
              this.UpdateStatusAppointment(slot);
            });
        });
    },
    UpdateStatusAppointment(slot) {
      slot.raw = {};
      slot.status = "In Progress";

      this.store
        .dispatch(Actions.UPDATE_SLOT, slot)
        .then(() => {
          let slot_log = {
            slot_id: slot.id,
            type: "Updated Appointment Status",
            raw: {
              status: slot.status,
            },
          };

          this.updateLog(slot_log);

          Swal.fire({
            title: "Congratulation!",
            text: "Appointment session has started!",
            icon: "success",
          }).then(() => {
            window.location.replace(
              "#/customers/details/" + this.cstmr_id + "/appointment/" + slot.id
            );
          });
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    updateLog(log) {
      this.store.dispatch(Actions.STORE_SLOT_LOG, log).then(() => {
        // console.log("log updated");
      });
    },
    showSaveSessionError() {
      Swal.fire({
        icon: "error",
        title: "Session Error",
        text: "We encountered an issue. Please try again later.",
        footer: "If the problem persists, please contact support.",
      });
    },
    snakeToTitle(str) {
      return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
  async mounted() {
    this.init();
  },
});
